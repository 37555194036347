import React from 'react'
import './Espanol.css'

function Espanol() {
  return (
    <div className='espanol'>
      Se Habla Español!
    </div>
  )
}

export default Espanol