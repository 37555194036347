import React from 'react'
import './About.css'

function About() {
  return (
    <div className='about-container'>
      <h3 className="about-title">Towing Services For Seattle, Bellevue And The Surrounding Areas</h3>
      <div className="about-content-container">
        <img src="https://static.displate.com/857x1200/displate/2019-09-11/d61efd6da99e0c56c3f4803b9eaed358_ceb9195be5f80754e044e56eca5303b8.jpg" alt="Seattle, towing WA 98121" className='seattle-img'/>
        <div className="blurb-container">
          <p className="about-content-intro">Chris Towing has been helping customers in the greater metropolitan area.</p>
          <p className="about-content">Whether it's a dead battery, lockout, winch out or you just need your vehicle towed we're here to help.
          <br />
          <br />
          Transporting Electric and Hybrid vehicles, trucks, vans and more.
          <br />
          <br />
          <span className="junk-cars">We buy junk cars.</span>
          <br />
          <br />
          Here to serve you 24 hours a day 7 days a week.
          <br />
          <br />
          Se Habla Español.
          </p>
        </div>
      </div>
    </div>
  )
}

export default About