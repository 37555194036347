import React from 'react'
import CallButton from './CallButton'
import './NavBar.css'
import { FaPhone } from 'react-icons/fa'
import Espanol from './Espanol'

function NavBar() {
  return (
    <div className='nav-container'>
      <div className="logo-div">
        {/* logo can ge it's own component */}
        <h3 className="logo">CHRIS TOWING</h3>
        <h4 className="logo-llc">& Transportation LLC</h4>
      </div>
      
      <div className="nav-items-container">
        <div className="contact">CONTACT US</div>
        <div className="nav-phone">
          <span className='service-area'>Serving The Greater Seattle Area </span><br className='media-break-phone' /><FaPhone className='phone-icon' /> (206) 513-4383
        </div> 
        {/* above stuff turns to call now button when scrolled down
        {/* <CallButton /> */}
      </div>
    </div>
  )
}

export default NavBar