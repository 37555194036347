import React from 'react'

function CallButton() {

  const callButtonStyles = {
    color: 'rgba(1, 0, 78, 0.842)',
    alignSelf: "center",
    textDecoration: "none",
    border: "none",
    borderRadius: 5,
    letterSpacing: 3,
    backgroundColor: "gold",
    padding: "10px 30px 10px 30px",
    fontSize: 25,
    fontWeight: "bold",
  }

  return (
    <><a href="tel:(206) 513-4383" style={callButtonStyles}>CALL NOW</a></>
  )
}

export default CallButton